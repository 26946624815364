import React from "react";
import "./AboutContent.css";

function ProductionsContent() {
  return (
    <>
      <section className="hero">
        <div className="heading">
          <h1>Current Productions</h1>
        </div>
      </section>

      <br></br>
      <div className="container">
        <div className="hero-content">
          <h2>Snowed In</h2>
          <h3>Improv Comedy</h3>
          <h4>Judy's Beat Lounge @ Second City</h4>
          <p>Saturday Nights @ 7:00pm</p>
          <p>January 4th - 25th</p>
          <button type="button" class="btn btn-outline-dark btn-rounded">
            <a href="https://www.secondcity.com/shows/chicago/yes-ahhh-presents-snowed-in-chi?fbclid=IwZXh0bgNhZW0CMTAAAR3GfKacQS7DPcbQVfEI0o_hrAo-VYliVxaUW9Bsoo3PMcZB9Gaw-N0BXj8_aem_kh7--YMtrPh7J9vqjJZNvA">
              TICKETS
            </a>
          </button>
        </div>
        <div className="hero-image-left">
          <img src="images/snowedin.jpg" alt="snowedin" />
        </div>
      </div>
      <br></br>
      <div className="container reverse-order">
        <div className="hero-image-left">
          <img src="images/bloodyvalentine.jpg" alt="bloodyvalentine" />
        </div>
        <div className="hero-content">
          <h2>Bloody Valentine</h2>
          <h3>Improvized Horror Comedy</h3>
          <h4>IO Theater</h4>
          <p>Thursday Nights @ 9:00pm</p>
          <p>February 6th - 27th</p>
          <button type="button" class="btn btn-outline-dark btn-rounded">
            <a href="https://ci.ovationtix.com/36725/production/1228401?performanceId=11588042">
              TICKETS
            </a>
          </button>
        </div>
      </div>

      <br></br>
      <div className="container">
        <div className="hero-content">
          <h2>Prov Ur Love 2 Me</h2>
          <h3>Improv Comedy</h3>
          <h4>Blackout Carabet @ Second City</h4>
          <p>Friday Nights @ 9:00pm</p>
          <p>February 7th - 28th</p>
          <button type="button" class="btn btn-outline-dark btn-rounded">
            <a href="https://www.secondcity.com/shows/chicago/love-prov-island-chi?fbclid=IwY2xjawH_vEFleHRuA2FlbQIxMAABHTn9dr35fysXDPS-MPCFWKFBbh2kghG-XMpFgveLVv2vQz_7PHiEm43Xhw_aem_S784OyoloVBBmaEOMJiskg">
              TICKETS
            </a>
          </button>
        </div>
        <div className="hero-image-left">
          <img src="images/provlove.jpg" alt="provlove" />
        </div>
      </div>
      <br></br>
      <div className="container reverse-order">
        <div className="hero-image-left">
          <img src="images/kittyandkellie.png" alt="kittyandkellie" />
        </div>
        <div className="hero-content">
          <h2>The Kitty and Kellie Show</h2>
          <h3>Improv/Sketch Comedy</h3>
          <h4>Judy's Beat Lounge @ Second City</h4>
          <p>Saturday Nights @ 8:30pm</p>
          <p>February 8th - March 1st</p>
          <button type="button" class="btn btn-outline-dark btn-rounded">
            <a href="https://www.secondcity.com/shows/chicago/the-kitty-kellie-show-chi?fbclid=IwZXh0bgNhZW0CMTAAAR1v0NykmqUQ5aAp8dClg3bhqepfqHdqKEqNItJeIauvsm0gaVdrtEgQl_g_aem_ZSZJ6O07EoMmtZpGlUjuLw">
              TICKETS
            </a>
          </button>
        </div>
      </div>

      <section className="hero">
        <div className="heading">
          <h1>Past Productions</h1>
        </div>
      </section>
      <br></br>
      <div className="container">
        <div className="hero-content">
          <h2>The Propular Kidz</h2>
          <h3>Improv Comedy</h3>
          <h4>Blackout Carabet @ Second City</h4>
          {/* <button type="button" class="btn btn-outline-dark btn-rounded">
            <a href="https://www.secondcity.com/shows/chicago/the-propular-kidz-chi">
              TICKETS
            </a>
          </button> */}
        </div>
        <div className="hero-image">
          <img src="images/propkids_new.jpg" alt="propkiz" />
        </div>
      </div>
      <br></br>

      <div className="container reverse-order">
        <div className="hero-image-left">
          <img src="images/1920x1080_YesAhh.png" alt="yesahh" />
        </div>
        <div className="hero-content">
          <h2>Yes AHHH!</h2>
          <h3>Improv Comedy</h3>
          <h4>Blackout Carabet @ Second City</h4>
        </div>
      </div>
      <br></br>
      <div className="container">
        <div className="hero-content">
          <h2>Puff, Puff, Laugh (Vol. 3)</h2>
          <h3>Sketch Comedy</h3>
          <h4>Judy's Beat Lounge @ Second City</h4>
        </div>
        <div className="hero-image">
          <img src="images/puffpuffvol3.png" alt="puffpuffposter" />
        </div>
      </div>
      <br></br>
      <div className="container reverse-order">
        <div className="hero-image-left">
          <img src="images/8.5x11_flyer.jpeg" alt="propkidz" />
        </div>
        <div className="hero-content">
          <h2>The Propular Kidz</h2>
          <h3>Improv Comedy</h3>
          <h4>Blackout Carabet @ Second City</h4>
        </div>
      </div>
      <br></br>
      <div className="container">
        <div className="hero-content">
          <h2>Puff, Puff, Laugh (Vol. 2)</h2>
          <h3>Sketch Comedy</h3>
          <h4>The Annoyance Theater</h4>
        </div>
        <div className="hero-image">
          <img src="images/puffpuffvol2.png" alt="puffpuffposter2" />
        </div>
      </div>
    </>
  );
}

export default ProductionsContent;
